import { template as template_99f1a92f086a4f28b31eb52480571663 } from "@ember/template-compiler";
const WelcomeHeader = template_99f1a92f086a4f28b31eb52480571663(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
