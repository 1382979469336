import { template as template_cafc52920f0e4e15bd41bd7e45db3d67 } from "@ember/template-compiler";
const FKLabel = template_cafc52920f0e4e15bd41bd7e45db3d67(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
