import { template as template_ef339a737a804725ab0ca3afb20eeb3b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_ef339a737a804725ab0ca3afb20eeb3b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
